//
import PCategoryService from '@/services/api/Provider/PCategory'
import PProviderService from '@/services/api/Provider/PProvider'
import GRegionService from '@/services/api/General/GRegion'
import RulesService from '@/services/rules'

//
export default {
  name: 'v-view-provider',
  data() {
    return {
      busy: false,
      gCategories: [],
      gCommunes: [],
      gProvinces: [],
      gRegions: [],
      pProvider: {},
      titleProps:{
        //props of title doest work
      },
      propsSubmit:{
        color:'secondary',
        plain:false,
        text:false,
      },
      resetProps:{
        color:'primary',
        text:false,
      }

    }
  },
  methods: {

    /**
     * 
     */
    getCategories() {
      PCategoryService.getAll().then(res => {
        this.gCategories = res.data
      })
    },

    /**
     * 
     */
    getRegions() {
      GRegionService.getAllWithCommune().then(res => {
        this.gRegions = res
      })
    },

    /**
     * 
     */
    handleFormError() {
      this.$toast.error('El formulario posee errores. Se deben resolver antes de continuar.')
    },

    /**
     * 
     */
    async handleFormReset() {
      this.busy = true
      await PProviderService.getByIdWhitRegions(this.$provider.id).then(this.handleGetByIdSuccess).catch(this.handleGetByIdError)
      this.busy = false
    },

    /**
     * 
     */
    async handleFormSubmit() {
      this.busy = true
      await PProviderService.update(this.pProvider).then(this.handleLoginSuccess).catch(this.handleLoginError)
      this.busy = false
    },

    /**
     * 
     */
    handleLoginError(payload) {
      PProviderService.translate(payload).then(this.$toast.error)
    },

    /**
     * 
     */
    async handleLoginSuccess(payload) {
      PProviderService.translate({ response: payload }).then(this.$toast.success)
    },

    /**
     * 
     */
    handleGetByIdError() {
      this.$toast.error('Error al intentar obtener datos de proveedor.')
    },

    /**
     * 
     */
    handleGetByIdSuccess(pProvider) {
      this.pProvider = pProvider
    },

    /**
     * 
     */
    handleRegionRefresh() {
      console.log(this.getRef('regionId').selectedItems)
      if (this.getRef('regionId').getSelectedItem()) {
        this.gProvinces = this.getRef('regionId').getSelectedItem().getProvinces()
      }
    },
  },
  created() {
    this.handleFormReset()
    this.getCategories()
    this.getRegions()
  },
  props: {

    /**
     * 
     */
    addressRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.max(255),
      ],
    },

    /**
     * 
     */
    communeRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.select,
      ],
    },

    /**
     * 
     */
    emailRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.email,
        RulesService.max(40),
      ],
    },

    /**
     * 
     */
    nameRules: {
      type: Array,
      default: () => [
        RulesService.required,
      ],
    },

    /**
     * 
     */
    provinceRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.select,
      ],
    },

    /**
     * 
     */
    regionRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.select,
      ],
    },

    /**
     * 
     */
    rutRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.rut,
        RulesService.max(12),
      ],
    },

    /**
     * 
     */
    webUrlRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.url,
        RulesService.max(120),
      ],
    },
  },
  watch: {

    /**
     * 
     */
    gProvinces() {
      if (this.gCommunes.length) {
        this.gCommunes = []
      }
    },

    /**
     * 
     */
    gRegions() {
      this.gRegions.forEach(region => {
        if (region.id == this.pProvider.regionId) {
          this.gProvinces = region.provinces
        }
      })
    },

    /**
     * 
     */
    'pProvider.regionId'(newRegionId, oldRegionId) {
      this.gRegions.forEach(region => {
        if (region.id == newRegionId) {
          this.gProvinces = region.provinces
          if (oldRegionId && newRegionId != oldRegionId) {
            this.pProvider.provinceId = null
            this.pProvider.communeId  = null
          }
        }
      })
    },

    /**
     * 
     */
    'pProvider.provinceId'(newProvinceId, oldProvinceId) {
      this.gProvinces.forEach(province => {
        if (province.id == this.pProvider.provinceId) {
          this.gCommunes = province.communes
          if (oldProvinceId && newProvinceId != oldProvinceId) {
            this.pProvider.communeId  = null
          }
        }
      })
    },
  }
}